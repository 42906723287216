.root {
  border: 1px solid;
  border-radius: 5;
  border-color: #d6d6d6;
}

.header {
  padding: 20px 32px;
  border-bottom: 1px solid;
  border-color: #d6d6d6;
  align-items: center;
  justify-content: space-between;
  display: flex;
}

.tableContainer {
  overflow: auto;
}
.tHead {
  font-weight: 600;
  font-size: 16px !important;
  color: #000 !important;
  text-align: center;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 4rem;
  padding-right: 4rem;
}

.tBody {
  font-size: 14px !important;
  font-weight: 400;
  color: "#000";
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  & a {
    font-size: 14px !important;
    font-weight: 400;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
