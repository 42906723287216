.nav {
  display: flex;
  justify-content: space-between;
  padding: 16px 32px;
  top: 0;
  height: 70px;
  width: 100vw;
  align-items: center;
  position: fixed;
  z-index: 10;
  background-color: white;
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
}
