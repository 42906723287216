.navbar {
  height: 100%;
  width: 265px;
  padding: var(--mantine-spacing-md);
  display: flex;
  flex-direction: column;
  position: fixed;
  border-right: 1px solid
    light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));
  box-shadow: rgba(33, 35, 38, 0.1) 10px 0px 10px -10px;
}

.navbarMain {
  flex: 1;
}

.header {
  padding-bottom: var(--mantine-spacing-sm);
  margin-bottom: calc(var(--mantine-spacing-sm));
  border-bottom: 1px solid
    light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));
}

.footer {
  padding-top: var(--mantine-spacing-md);
  margin-top: var(--mantine-spacing-md);
  align-self: flex-end;
  border-top: rem(1px) solid
    light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));
}

.link {
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: var(--mantine-font-size-sm);
  color: light-dark(var(--mantine-color-gray-7), var(--mantine-color-dark-1));
  padding: var(--mantine-spacing-xs) var(--mantine-spacing-sm);
  border-radius: var(--mantine-radius-sm);
  font-weight: 500;

  @mixin hover {
    background-color: light-dark(
      var(--mantine-color-gray-0),
      var(--mantine-color-dark-6)
    );
    color: light-dark(var(--mantine-color-black), var(--mantine-color-white));

    .linkIcon {
      color: light-dark(var(--mantine-color-black), var(--mantine-color-white));
    }
  }

  &[data-active] {
    &,
    &:hover {
      background-color: var(--mantine-color-blue-light);
      color: var(--mantine-color-blue-light-color);

      .linkIcon {
        color: var(--mantine-color-blue-light-color);
      }
    }
  }
}

.linkIcon {
  color: light-dark(var(--mantine-color-gray-6), var(--mantine-color-dark-2));
  margin-right: var(--mantine-spacing-sm);
  width: rem(25px);
  height: rem(25px);
}
