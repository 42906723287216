.input{
    border-radius: 8px;
}
.input:focus{
    border-color: #24A94A;
    border-width: 2px;
}
.input:focus-within{
    border-color: #24A94A ;
    border-width: 2px;
}
